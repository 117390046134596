<template>
<div class="bg-default">
    <header class="header">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings" />
            </a>
        </div>
        <div class="header-center justify-end">
            <div class="header-card">
                <div class="header-card-text">
                    hátralévő<br />
                    idő
                </div>
                <market-timer @roundEnded="roundEnd()"
            :startTime="{time: this.$config.settings.difficultyWaitingTime, option: 'round'}"></market-timer>
            </div>
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit" />
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="card card-800 card-centerred">
                <div class="card-info mt-16">
                    Szükséged lesz dollárra a tőzsdei kereskedéshez!
                </div>
                <div class="card-subtitle mt-32 mb-32">
                    Kattints az árfolyamra a váltáshoz!
                </div>
                <div class="card-info mb-48">
                    Maximum 50.000 tallér lesz átváltva.
                </div>
                <div class="card-currencies mb-16">
                    <div class="card-currencies-group mb-32">
                        <div class="card-currencies-group-title">Valuta</div>
                        <div class="card-currencies-group-buttons">
                            <div class="card-currencies-group-buttons-title">Vétel</div>
                            <a @click="buyUsd('buyValuta')" class="button button-blue">{{ this.$config.game.prices.valutaPurchaseRate }} tallér /
                                usd</a>
                        </div>
                        <div class="card-currencies-group-buttons">
                            <div class="card-currencies-group-buttons-title">Eladás</div>
                            <a @click="buyUsd('sellValuta')" class="button button-blue">{{ this.$config.game.prices.valutaSellRate }} tallér / usd</a>
                        </div>
                    </div>
                    <div class="card-currencies-group">
                        <div class="card-currencies-group-title">Deviza</div>
                        <div class="card-currencies-group-buttons">
                            <a @click="buyUsd('buyDeviza')" class="button button-blue">{{ this.$config.game.prices.devizaPurchaseRate }} tallér /
                                usd</a>
                        </div>
                        <div class="card-currencies-group-buttons">
                            <a @click="buyUsd('sellDeviza')" class="button button-blue">{{ this.$config.game.prices.devizaSellRate }} tallér / usd</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
        <div class="container">
            <div class="footer-info">
                <div>
                    Összes eddig gyűjtött tallérod: <strong>{{ balance }}</strong>
                </div>
                <div>III. forduló kamatjóváírás: <strong>88888</strong></div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketTimer from "../components/MarketTimer.vue";

export default {
  components: { MarketTimer },
  computed: {
    ...mapGetters(["balance"]),
  },
  methods: {
    buyUsd(option) {
      this.$store.commit("setExchangeDecision", option);
      this.$store.dispatch("storeGameplayEvent", {
        round: 1,
        event: "action",
      });
      this.$router.push("rating");
    },

    goNext() {
      this.$router.push("rating");
    },
    roundEnd() {
        this.$store.commit("setExchangeDecision", "buyDeviza");
        this.$store.dispatch("storeGameplayEvent", {
        round: 1,
        event: "action",
      });
      this.goNext();
    },
  },
};
</script>

<style>
</style>
